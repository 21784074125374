import React from "react"
import Container from "../container/container"
import style from "./simple-intro.mod.scss"

const SimpleIntro = ({ title, text }) => {
  return (
    <Container pt={3}>
      <div className={style.intro}>
        <h1>{title}</h1>
        {text && <p>{text}</p>}
      </div>
    </Container>
  )
}

export default SimpleIntro
