import React from "react"
import { graphql } from "gatsby"
import style from "./pathways.mod.scss"
import BorderWrapper from "../border-wrapper/border-wrapper"
import PanelRow from "../panel-row/panel-row"
import Container from "../container/container"
import Button from "../button/button"

const Pathways = ({ data, topColor = "light", bottomColor = "light" }) => {
  const pathwayColours = ["primary", "plum", "dark"]
  return (
    <Container noGutters>
      <BorderWrapper topColor={topColor} bottomColor={bottomColor}>
        <PanelRow
          panels={data.map((pw, i) => {
            return {
              image: pw.backgroundImage,
              bgColor: pathwayColours[i],
              opacity: 9,
              content: (
                <Container
                  pt={4}
                  pb={4}
                  pl={3}
                  pr={3}
                  addBorderPadding
                  textAlign="center"
                  className={style.pathway}
                >
                  <div className={style.pathway__inner}>
                    <h3>{pw.heading}</h3>
                    <p>{pw.text}</p>
                    <div>
                      <Button light recordId={pw.buttonLink.originalId}>
                        {pw.buttonText}
                      </Button>
                    </div>
                  </div>
                </Container>
              ),
            }
          })}
        />
      </BorderWrapper>
    </Container>
  )
}

export default Pathways

export const query = graphql`
  fragment Pathways on DatoCmsPathway {
    heading
    text
    buttonText
    buttonLink {
      ... on DatoCmsCollectionParent {
        originalId
      }
      ... on DatoCmsInnerPage {
        originalId
      }
      ... on DatoCmsRegionChild {
        originalId
      }
      ... on DatoCmsRegionParent {
        originalId
      }
      ... on DatoCmsHomepage {
        originalId
      }
      ... on DatoCmsPost {
        originalId
      }
      ... on DatoCmsNewsPage {
        originalId
      }
      ... on DatoCmsWineriesPage {
        originalId
      }
      ... on DatoCmsRegionsPage {
        originalId
      }
      ... on DatoCmsGrape {
        originalId
      }
      ... on DatoCmsWine {
        originalId
      }
      ... on DatoCmsPairing {
        originalId
      }
      ... on DatoCmsCellar {
        originalId
      }
    }
    backgroundImage {
      gatsbyImageData(
        layout: FULL_WIDTH
        imgixParams: { h: "600", fit: "crop", w: "600", q: 60 }
      )
      alt
    }
  }
`
