import React from "react"
import cn from "classnames"
import style from "./panel-row.mod.scss"
import { GatsbyImage } from "gatsby-plugin-image"
import demoImage from "../../assets/images/stock-winery.jpeg"

const PanelRow = ({ panels }) => {
  if (!panels || panels.length === 0) {
    return null
  }

  const handleImgError = (e) => {
    e.target.src = demoImage
  }

  return (
    <div className={style.panelRow}>
      {panels.map((panel, i) => {
        let s = {}
        if (panel.order) {
          s.order = panel.order
        }
        return (
          <div
            className={cn(
              style.panelRow__col,
              panel.className,
              {
                [`${style.panelRow__col}-empty`]: !panel.content,
              }
            )}
            style={s}
            key={`panel${i}`}
          >
            {panel.image && (
              <>
                {panel.image.gatsbyImageData ? (
                  <GatsbyImage
                    image={panel.image.gatsbyImageData}
                    alt={panel.image.alt ? panel.image.alt : ""}
                    className={style.panelRow__img}
                  />
                ) : (
                  <img className={style.panelRow__portal_image} src={panel.image} alt="" onError={handleImgError} />
                )}
              </>
            )}
            {panel.bgColor && (
              <div
                className={cn(
                  style.panelRow__overlay,
                  `${style.panelRow__overlay}-${panel.bgColor}`,
                  {
                    [`${style.panelRow__overlay}-opacity${panel.opacity}`]:
                      panel.opacity,
                  }
                )}
              />
            )}
            {panel.content}
          </div>
        )
      })}
    </div>
  )
}

export default PanelRow
