import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/global/layout"
import Container from "../components/container/container"
import SimpleIntro from "../components/global/simple-intro"
import Pathways from "../components/pathways/pathways"
import ProductSlider from "../components/product/product-slider"

const PageNotFound = ({ data: { datoCmsHomepage, newArrivals } }) => {
  return (
    <div>
      <Layout>
        <Container gutters width={"medium"} pb={2}>
          <SimpleIntro title={"Page not found"} />
          <p>Sorry the page you were looking for is currently not available.</p>
        </Container>
        <Pathways data={datoCmsHomepage.pathways} bottomColor="light" />
        <Container pt={2} pb={2} bg={"light"} width={"none"}>
          <ProductSlider
            heading={"New arrivals"}
            subHeading={"Our wines"}
            items={newArrivals.nodes}
          />
        </Container>
      </Layout>
    </div>
  )
}

export default PageNotFound

export const query = graphql`
  {
    datoCmsHomepage {
      pathways {
        ...Pathways
      }
    }
    newArrivals: allPortalWine(
      filter: { wine_tags: { elemMatch: { name: { eq: "New Arrivals" } } } }
    ) {
      nodes {
        id
        name
        price
        image
        winery {
          id
          minimum_wines_per_order
        }
      }
    }
  }
`
